// SSC.js
import React, { useState, useEffect, useContext } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BatchContext } from '../BatchContext';  // Import the BatchContext
import useDisableBackButton from '../useDisableBackButton'; // Adjust the path

const SSCPage = () => {
  useDisableBackButton(); // Disable the browser back/forward buttons
  const { setBatch } = useContext(BatchContext);

  
  // Initialize selectedTab from localStorage or default to 0
  const [selectedTab, setSelectedTab] = useState(() => {
    const savedTab = localStorage.getItem('selectedTab');
    return savedTab !== null ? parseInt(savedTab, 10) : 0;
  });

  // Save selectedTab to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedTab', selectedTab);

    // Update batch based on selectedTab
    if (selectedTab === 0) {
      setBatch('SSC');
    } else if (selectedTab === 1) {
      setBatch('UPSC');
    } else if (selectedTab === 2) {
      setBatch('POLICE');
    }
  }, [selectedTab, setBatch]);

  // Ensure batch is set correctly when component mounts
  useEffect(() => {
    if (selectedTab === 0) {
      setBatch('SSC');
    } else if (selectedTab === 1) {
      setBatch('UPSC');
    } else if (selectedTab === 2) {
      setBatch('POLICE');
    }
    // Only run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);

    if (newValue === 0) {
      setBatch('SSC');
    } else if (newValue === 1) {
      setBatch('UPSC');
    } else if (newValue === 2) {
      setBatch('POLICE');
    }

    navigate('/');
  };





  const { chapters, loading, error, batch } = useContext(BatchContext);  // Access the current batch and chapters
  
  const navigate = useNavigate();

  const handleChapterClick = (chapterId) => {
    navigate(`/content/${chapterId}`);  // Navigate to the ContainerPage with chapterId
  };

  if (loading) {
    // Show skeleton effect while loading
    return (
      <div>
        <p>Loading {batch} Chapters...</p>
      </div>
    );
  }

  if (error) return <div>Error: {error}</div>;
  
 // Helper function to capitalize the first letter of the topic
 const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

return (
  <>
        
      {/* Tab system just below the AppBar */}
      <Box position='sticky' sx={{ top:0,zIndex:'2', backgroundColor: '#f5f5f5', 
        marginLeft:'-25px',marginRight:'-25px' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          <Tab label='SSC Exam' />
          <Tab label='UPSC exam ' />
          <Tab label='Police Exam'/>
        </Tabs>
      </Box>
  <div className="chapter-list">
    {chapters.map((subject, index) => {
      // Split chapter name by "("
      const [chapterName, part] = subject.chapter.name.split(/\(|\)/);
      
      return (
        <div key={index} className="card-container" onClick={() => handleChapterClick(index)}>
          <div className="card-badge">{batch} {subject.subject}</div>

          {/* Dynamically set the image based on the subject name */}
          <div className="card-image-container">
            <img
              className="card-image"
              src={`./images/${subject.subject}.webp`} // Assuming the images are named based on subject
              alt={`${subject.subject}.webp`}
            />
          </div>

          <div className="card-content">
            {/* Show the chapter name and the part inside parentheses in a new line with different color */}
            <h3 className="card-heading">
              {chapterName} 
              {part && (
                <span style={{ color: '#FD6472'
                }}>({part.trim()})</span>
              )}
            </h3>

            {/* Show topic with first letter capitalized */}
            <h3 style={{marginTop:'-8px'}}>
              Topic - <span>{capitalizeFirstLetter(subject.chapter.topic)}</span>
            </h3>

            <button className="card-button">Explore</button>
          </div>
        </div>
      );
    })}
  </div>
  </>
);
}

export default SSCPage;
