import React from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import styles from './Help.module.css'; // Importing the CSS module

function HelpPage() {
  const navigate = useNavigate();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <React.Fragment>
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Help | Support
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Space to prevent content from being overlapped by AppBar */}
      <Box p={3}>
        <div className={styles.helpContainer}>
            {/* Topics Section */}
            <section className={styles.helpcategories}>
              <h3 className="title">Topics</h3>
              <div className={styles.helpcategoryList}>
                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                   <span className={styles.helpcategoryIcon}>&#x1F680;</span>
                    Getting Started</h4>
                    <p className={styles.helpcategoryDescription}>
                      What is PriceClasses, getting started & more
                    </p>
                  </a>
                </li>

                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                   <span className={styles.helpcategoryIcon}>&#x1F4B3;</span>
                    My Balance</h4>
                    <p className={styles.helpcategoryDescription}>
                      Winnings, Withdrawals, Deposits & more
                    </p>
                  </a>
                </li>

                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                   <span className={styles.helpcategoryIcon}>&#x1F464;</span>
                    Profile & Verification</h4>
                    <p className={styles.helpcategoryDescription}>
                      View/Modify details, Verification & more
                    </p>
                  </a>
                </li>

                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                   <span className={styles.helpcategoryIcon}>&#x1F3AE;</span>
                    How to win contests</h4>
                    <p className={styles.helpcategoryDescription}>
                      Create Teams, Join Contests & more
                    </p>
                  </a>
                </li>

                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                   <span className={styles.helpcategoryIcon}>&#x1F381;</span>
                    Offers & Rewards</h4>
                    <p className={styles.helpcategoryDescription}>
                      Invite Friends, D11 Champions & more
                    </p>
                  </a>
                </li>

                <li className={styles.helpcategoryItem}>
                  <a href="#">
                    <h4 className={styles.helpcategoryTitle}>                  <span className={styles.helpcategoryIcon}>&#x1F4F6;</span>
                     Others</h4>
                    <p className={styles.helpcategoryDescription}>
                      Network, Security & Legality
                    </p>
                  </a>
                </li>
              </div>
            </section>

            {/* Contact Us Section */}
            <section className={styles.helpcontactUsSection}>
              <h3>Can’t find what you're looking for?</h3>
              <a className={styles.helpcontactUsLink} href="#">
                Contact Us
              </a>
            </section>
          </div>
      </Box>
    </React.Fragment>
  );
}

export default HelpPage;
