// components/LayoutWithAds.js
import React from 'react';
import { useMediaQuery } from '@mui/material';
import './LayoutWithAds.css'; // Ensure to adjust the styling here

const LayoutWithAds = ({ children }) => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Check if the screen is mobile size

  return (
    <div className="layout-container">
      {/* Only show ads if not on mobile */}
      {!isMobile && (
        <div className="left-sidebar">
          {/* Replace this with actual ad components */}
          <p>Ad Space</p>
        </div>
      )}
      <div className={`main-content ${isMobile ? 'full-width' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default LayoutWithAds;


