import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PdfViewPage = () => {
  const { pdfId } = useParams(); // Get PDF ID from URL
  const [pdfUrl, setPdfUrl] = useState('');
  const navigate = useNavigate();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };

  useEffect(() => {
    const url = `https://prizeclasses.in/pdf/${pdfId}.pdf`;

    // Check if PDF is accessible
    fetch(url)
      .then((res) => {
        if (res.ok) {
          setPdfUrl(url); // Set the PDF URL if found
        } else {
          window.location.href = 'https://prizeclasses.in'; // Redirect if not found
        }
      })
      .catch(() => {
        window.location.href = 'https://prizeclasses.in'; // Redirect on error
      });
  }, [pdfId]);

  return (
    <div>
       {/* AppBar */}
       <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Study Materials
          </Typography>
        </Toolbar>
      </AppBar>

    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {pdfUrl ? (
        <iframe
          src={pdfUrl}
          title="PDF Viewer"
          width="100%"
          height="100%"
          style={{ border: 'none',}}
        />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
    </div>

  );
};

export default PdfViewPage;
