import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton,Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppContext } from '../AppContext';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import useDisableBackButton from '../useDisableBackButton'; // Adjust the path

const JoinPage = () => {
  useDisableBackButton(); // Disable the browser back/forward buttons
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [contests, setContests] = useState([]);
  const [wallet, setWallet] = useState('00.00');
  const [valid, setValid] = useState('valid');
  const [check, setCheck] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { showModal, hideModal } = useAppContext();

  useEffect(() => {
    const user = localStorage.getItem('userdata');
    if (user) {
    }else{
      showModal({
        circularProgress: false,
        topic: 'UserID Not Found',
        description: 'Please log in to continue.',
        primaryBtnText: 'Login',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login');

        }
      });
      return;
    }
  }, [hideModal, navigate , showModal]);

  const fetchContestDataWithGet = useCallback(() => {
    setLoading(true);
    const contestId = localStorage.getItem('CId');
    console.log('contestId',contestId)
    const user = localStorage.getItem('userdata');
    const userdata = JSON.parse(user);
    const userId = userdata.user;
    if (!contestId) {
      showModal({
        circularProgress: false,
        topic: 'Contest Not Found',
        description: 'Please log in to continue.',
        primaryBtnText: 'Login',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login');

        }
      });
      return;
    }
    
    fetch(`https://prizeclasses.in/sql/contestprize.php?contestId=${contestId}&userId=${userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'error') {
          setContests(data);
          console.log('Response from server:', data);
          // If data is an array, destructure it to get the first object.
        const [contestData] = data;
        // Access 'balance' from the contestData object.
        const balance = contestData?.balance;
          setWallet(balance)
          setLoading(false);
        } else {
          showModal({
            circularProgress: false,
            topic: 'Error',
            description: `Failed to retrieve contest details: ${data.message}`,
            primaryBtnText: 'Retry',
            primaryBtnColor: 'orange',
            hidePrimary: false,
            onPrimaryClick: () => {
              hideModal();
              fetchContestDataWithGet();
            }
          });
          setLoading(false);
          console.error('Error in response:', data.message);
        }
      })
      .catch((error) => {
        showModal({
          circularProgress: false,
          topic: 'Network Error',
          description: 'Unable to reach the server. Please check your connection and try again.',
          primaryBtnText: 'Retry',
          primaryBtnColor: 'orange',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
            fetchContestDataWithGet();
          }

        });
        setLoading(false);
        console.error('Error fetching contest data:', error);
      });
  }, [hideModal,showModal,navigate]);

  useEffect(() => {
    fetchContestDataWithGet();
  }, [fetchContestDataWithGet]);



  useEffect(() => {
    const interval = setInterval(() => {
      setContests((prevContests) =>
        prevContests.map((contest) => {
          const currentTime = new Date().getTime();
          const contestStartTime = new Date(contest.startTime).getTime();
          const revisionEndTime = contestStartTime + 120 * 1000;
          let newState = { ...contest };

          if (currentTime < contestStartTime) {
            newState.contestState = {
              state: 'join',
              timeRemaining: contestStartTime - currentTime,
            };
          } else if (currentTime >= contestStartTime && currentTime <= revisionEndTime) {
            newState.contestState = {
              state: 'revise',
              timeRemaining: revisionEndTime - currentTime,
            }
            console.log(contest.Joined)
            if (contest.Joined !=='true') {
              navigate('/');
              showModal({
                circularProgress: false,
                topic: 'No Entry',
                description:'You have not joined the contest in valid time limit',
                primaryBtnText: 'OK',
                hidePrimary: false,
                onPrimaryClick: () => {
                  hideModal();
                },
              });
            }
            if (check) {
              // console.log(currentTime);
              const contestId = localStorage.getItem('CId');
              setCheck(false);
              fetch(`https://prizeclasses.in/sql/check.php?contestId=${contestId}`)
                .then((response) => response.json()) // Parse the JSON response
                .then((data) => {
                  console.log('Response:', data);
                  // Access 'state' from the data object.
                  const status = data.state;
                  console.log('Valid:', status);
                  setValid(data);
                })
                .catch((error) => {
                  setCheck(true);
                  console.error('Error fetching data:', error);
                });
            }
          } else {
            newState.contestState = { state: 'expired', timeRemaining: 0 };
            if (valid.state !== 'suspended' && contest.Joined ==='true') {
              navigate('/entry');
            } else {
              navigate('/');
              showModal({
                circularProgress: false,
                topic: contest.Joined ==='true'?'Contest Suspended':'No Entry',
                description:contest.Joined ==='true'? valid.message:'You have not joined the contest in valid time limit',
                primaryBtnText: 'OK',
                hidePrimary: false,
                onPrimaryClick: () => {
                  hideModal();
                },
              });
              // Make sure to return here to ensure map gets a value
              return newState;
            }
          }
  
          // Ensure newState is returned for every iteration of map
          return newState;
        })
      );
    }, 1000);
  
    return () => clearInterval(interval);
  }, [contests, navigate, check, hideModal, showModal, valid.message, valid.state]);
  

  const handleJoinClick = async () => {
    
    const currentContest = localStorage.getItem('CId');

    // Show loading modal
    showModal({
      circularProgress: true,
      topic: 'Joining Contest...',
      description: 'Please wait...',
    });
    await delay(2000);

   

    // Find the selected contest and check if the user has already joined
    const selectedContest = contests.find(contest => contest.contestId === currentContest);
    if (selectedContest && selectedContest.Joined === 'true') {
      showModal({
        circularProgress: false,
        topic: 'Already Joined',
        description: 'You have already joined this contest.',
        primaryBtnText: 'OK',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
        }
      });
      return;
    }
    
    const user = localStorage.getItem('userdata');
    const userdata = JSON.parse(user);
    const userId = userdata.user;

    fetch(`https://prizeclasses.in/sql/joincontest.php?contestId=${currentContest}&userId=${userId}`)
      .then((response) => response.json())
      .then((join) => {
        if (join.status !== 'error') {
          setWallet(join.wallet_balance)
          showModal({
            circularProgress: false,
            topic: 'Success!',
            description: 'You have successfully joined the contest.',
            primaryBtnText: 'OK',
            hidePrimary: false,
            onPrimaryClick: () => {
              hideModal();
              fetchContestDataWithGet();
            }
          });
        } else {

          showModal({
            circularProgress: false,
            topic: join.topic,
            description: join.message,
            primaryBtnText: join.topic==='Joining Failed!'?'Retry':join.topic==='Cash Limits!'?'Add Cash':'OK',
            primaryBtnColor: join.topic==='Joining Failed!'?'orange':join.topic==='Cash Limits!'?'Green':'blue',
            hidePrimary: false,
            onPrimaryClick: () => {
              hideModal();
              fetchContestDataWithGet();
              if (join.topic==='Cash Limits!') {
                navigate('/wallet')
              }
            }
          });
        }
      })
      .catch((error) => {
        showModal({
          circularProgress: false,
          topic: 'Network Error'+error,
          description: (error.message),
          primaryBtnText: 'Retry',
          primaryBtnColor: 'orange',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
            fetchContestDataWithGet();
          }
        });
      });
  };


  if (loading) return <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center',height:'70vh'}}><div className='loading'>Loading...</div></div>  

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {contests.map((contest) => {
        const contestState = contest.contestState || {};

        const { state = 'join', timeRemaining = 0 } = contestState;
        const hours = Math.floor((timeRemaining / 1000 / 60/60) % 60);

        const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
        const seconds = Math.floor((timeRemaining / 1000) % 60);
        const percent = contest.player > 0 ? Math.floor((contest.totalJoined / contest.player) * 100) : '0';
        const left = contest.player > 0 ? Math.floor(contest.player - contest.totalJoined) : 0;


        return (
          <div key={contest.contestId} style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#A42FC1' }}>
              <Toolbar style={{justifyContent:'space-between'}}>
                <IconButton onClick={() => navigate('/')}>
                  <ArrowBackIcon style={{ color: 'white' }} />
                </IconButton>
                <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
                  {contest.contestType}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center',width:'120px',height:'35px',
                color: '#813588',
                backgroundColor: '#f3e5f5',
                borderRadius: '10px',justifyContent:'space-between' }}>
            
            <AccountBalanceWalletOutlinedIcon
              style={{
                padding: 2,
                color: '#813588',
              }}
              onClick={() =>navigate('/wallet')}
            />
            <div>₹{wallet}</div>
            <AddCircleIcon
              style={{
                padding: 2,
                color: 'green',
              }}
              onClick={() =>navigate('/wallet')}
            />
          </Box>
              </Toolbar>
            </AppBar>

            <div className="pcard-container" style={{ margin: '10px', backgroundColor: 'white', borderRadius: '10px', padding: '10px' }}>
              <div className={state === 'expired' ? 'epcard-badgebox' : state === 'revise' ? 'rpcard-badgebox' : 'pcard-badgebox'}>
                <div className="pcard-left">#{contest.contestId}</div>
                <div className={state === 'expired' ? 'epcard-badge' : state === 'revise' ? 'rpcard-badge' : 'pcard-badge'}>
                  {contest.batchName}
                </div>
              </div>
              <hr />
              <div className="flex">
                <div className="bar">
                  <p style={{ fontSize: '13px' }}>PrizePool</p>
                  <span style={{ fontWeight: '900', fontSize: '22px' }}>₹{contest.prizePool}</span>
                </div>
                <div className="barR">
                  {state === 'expired' ? (
                    <p style={{ margin: 0, fontSize: '12px', fontWeight: '600', color: 'red', background: 'pink', borderRadius: '5px', textAlign: 'center', width: '60px', height: '20px' }}>Expired</p>
                  ) : (
                    <p style={{ margin: 0, fontSize: '12px', fontWeight: '600', color: state === 'revise' ? 'green' : 'red', background: state === 'revise' ? '#C8E6C9' : 'pink', borderRadius: '5px', textAlign: 'center', width: '75px', height: '18px' }}>
                      {hours === 0 ? '' : hours + 'h'} {minutes}m {seconds}s
                      </p>
                  )}
                  {state !== 'expired' && <p style={{ margin: 0, fontSize: '13px' }}>{new Date(contest.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>}
                </div>
                <div className="barR">
                  <p className="pcard-left" style={{ margin: 0 }}>Duration<br />{contest.finishTime} sec</p>
                  <p className="pcard-left" style={{ fontSize: '12px', fontWeight: '900', color: state === 'revise' ? 'green' : 'white', background: 'orange', borderRadius: '5px', textAlign: 'center', }}>₹ {contest.entryFee}</p>
                </div>
              </div>
              <hr />
              <div className={state === 'expired' ? 'epcard-box' : state === 'revise' ? 'rpcard-box' : 'pcard-box'}>
                <div style={{ width: '30%', maxWidth: '75px', textAlign: 'right' }}>{left} Left</div>
                <div className="progress-bar-container">
                  <div className="progress-bar">
                    <div className="progress-fill-bar" style={{ backgroundColor: state === 'expired' ? 'gray' : state === 'revise' ? 'green' : '', width: `${percent}%` }}></div>
                  </div>
                </div>
                <div style={{ width: '35%', maxWidth: '80px', margin: '3px' }}>{contest.player} Spots</div>
              </div>
            </div>

            <div style={{ background: 'white', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', padding: '10px', overflow: 'hidden', height: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginLeft: '10px', marginRight: '10px' }}>
              <div style={{ flex: '1', marginBottom: '10px', padding: '5px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '13px', fontWeight: 'bold', color: 'dark', borderBottom: '1px solid lightblue', padding: '10px' }}>RANK
                  <div>WINNING</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', fontWeight: '600', borderBottom: '0.5px solid lightblue', padding: '10px' }}>🥇1st
                  <div>₹{contest.prize1st}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', fontWeight: '600', borderBottom: '0.5px solid lightblue', padding: '10px' }}>🥈2nd
                  <div>₹{contest.prize2nd}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', fontWeight: '600', borderBottom: '0.5px solid lightblue', padding: '10px' }}>🥉3rd
                  <div>₹{contest.prize3rd}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', fontWeight: '600', borderBottom: '0.5px solid lightblue', padding: '10px' }}>💸4th To 5th
                  <div>₹{contest.prize4th5th}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', fontWeight: '600', borderBottom: '0.5px solid lightblue', padding: '10px' }}>💸6th To 10th
                  <div>₹{contest.prize6th10th}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px', fontWeight: '600', borderBottom: '0.5px solid lightblue', padding: '10px',visibility: state === 'revise' && contest.Joined === 'true'? 'visible' :'hidden',color:'red'}}>
                <div>👉We are collecting all participants and players on one Place. Dont GO BAck!!! </div>
                  </div>
              </div>

              <button
                style={{
                  background: state === 'expired' ? 'gray' : state === 'revise' && contest.Joined === 'true'? 'green' : state === 'join' && contest.Joined === 'true' ? 'blue' :state === 'join' && contest.player <= contest.totalJoined ? 'red' :'blue',
                  color: 'white',
                  height: '40px',
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '14px',
                  fontWeight: '700',
                  cursor: state === 'expired' ? 'not-allowed' : contest.Joined === 'true' ? 'not-allowed' :'pointer',
                  
                }}
                onClick={() => handleJoinClick()}
              >
                {state === 'expired' ? 'Expired' : state === 'revise' && contest.Joined === 'true'? `Starts in ${minutes}m ${seconds}s`: state === 'join' && contest.Joined === 'true'? 'Already Joined' :state === 'join' && contest.player <= contest.totalJoined ? 'Already Full' :'Join'}

              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JoinPage;
