import React from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

function NotificationsPage() {
  const navigate = useNavigate();

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <React.Fragment>
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            How To Win Contest
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Space to prevent content from being overlapped by AppBar */}
      <Box>  
      <div className="how_to_play_box">
    <div className="right_steps_box">
        <ul>
            <li>
                <span className="step_number">1</span>
                <div className="title_img_box">
                    <div>
                        <div className="step_title_box">Choose a Batch</div>
                    </div>
                    <div>
                        <div className="winning_step_img">
                            <img src="https://prizeclasses.in/choose.webp" width="300" height="140" loading="lazy" alt="Choose Batch"/>
                        </div>
                    </div>
                </div>
                <div className="step_title">Select a batch based on your learning level and subject</div>
            </li>

            <li>
                <span className="step_number">2</span>
                <div className="title_img_box">
                    <div>
                        <div className="step_title_box">Learn Topics</div>
                    </div>
                    <div>
                        <div className="winning_step_img">
                            <img src="https://prizeclasses.in/batch.webp" width="300" height="140" loading="lazy" alt="Learn Topics"/>
                        </div>
                    </div>
                </div>
                <div className="step_title">Open the chapters and start learning important topics</div>
            </li>

            <li>
                <span className="step_number">3</span>
                <div className="title_img_box">
                    <div>
                        <div className="step_title_box">Join Contests</div>
                    </div>
                    <div>
                        <div className="winning_step_img">
                            <img src="https://prizeclasses.in/join.webp" width="300" height="140" loading="lazy" alt="Join Contests"/>
                        </div>
                    </div>
                </div>
                <div className="step_title">Join subject-related contests and compete for prizes</div>
            </li>
        </ul>
    </div>
</div>


    <div className="section">
        <h1>How to Win a Contest</h1>
        <p>At <strong>PriceClasses</strong>, we blend learning and competition, allowing you to sharpen your skills and win exciting rewards through our educational contests. Follow this step-by-step guide to maximize your chances of success and come out on top!</p>
    </div>

    <div className="section">
        <h2>1. Choose Your Batch</h2>
        <ul>
            <li><strong>Step 1:</strong> Log in to your PriceClasses account.</li>
            <li><strong>Step 2:</strong> Select the batch that aligns with your academic level and subjects of interest. Each batch will have tailored learning material and quizzes.</li>
        </ul>
        <p><strong>Tip:</strong> Choose your batch wisely. Pick a batch that covers subjects you are confident in and want to improve.</p>
    </div>

    <div className="section">
        <h2>2. Open Given Chapters</h2>
        <ul>
            <li><strong>Step 1:</strong> Once inside your batch, you’ll see the chapters and subjects available for learning.</li>
            <li><strong>Step 2:</strong> Select the chapters assigned to your batch and start exploring the material.</li>
        </ul>
        <p><strong>Tip:</strong> Prioritize chapters that will be covered in upcoming contests to ensure you’re well-prepared.</p>
    </div>

    <div className="section">
        <h2>3. Learn the Topics</h2>
        <ul>
            <li><strong>Step 1:</strong> Dive into the chapter's learning material. Read the topics, watch video tutorials (if available), and take notes.</li>
            <li><strong>Step 2:</strong> Engage actively with the content by understanding the key concepts.</li>
        </ul>
        <p><strong>Tip:</strong> Learning thoroughly is the foundation of doing well in contests. Make sure you understand the material, not just memorize it.</p>
    </div>

    <div className="section">
        <h2>4. Test Yourself (Practice Mode)</h2>
        <ul>
            <li><strong>Step 1:</strong> After completing a chapter, switch to <strong>Test/Practice Mode</strong>.</li>
            <li><strong>Step 2:</strong> Take quizzes or practice tests based on what you’ve learned. These practice tests will mimic the structure of the contest quizzes.</li>
        </ul>
        <p><strong>Tip:</strong> Use practice mode to identify weak areas. Take the test multiple times until you feel confident.</p>
    </div>

    <div className="section">
        <h2>5. Join Your Batch's Contest</h2>
        <ul>
            <li><strong>Step 1:</strong> After testing yourself, it’s time to take the real challenge. Head over to the <strong>Contest Section</strong>.</li>
            <li><strong>Step 2:</strong> Join a subject-specific or batch-specific contest related to the chapters you've studied.</li>
            <li><strong>Step 3:</strong> Ensure you register before the contest deadline to secure your spot.</li>
        </ul>
        <p><strong>Tip:</strong> Check contest details, including time limits, the number of questions, and scoring criteria.</p>
    </div>

    <div className="section">
        <h2>6. Open Revision Questions (Before Contest)</h2>
        <ul>
            <li><strong>Step 1:</strong> Before the contest begins, revisit key topics and concepts.</li>
            <li><strong>Step 2:</strong> Utilize the <strong>Revision Questions</strong> available for each chapter. These questions are designed to reinforce your knowledge and boost your confidence.</li>
        </ul>
        <p><strong>Tip:</strong> Focus on your weak areas while revising, but don’t skip general topics that will likely appear in the contest.</p>
    </div>

    <div className="section">
        <h2>7. Answer the Contest Questions</h2>
        <ul>
            <li><strong>Step 1:</strong> Once the contest starts, be prepared! Answer the questions in real-time based on the topics you’ve studied.</li>
            <li><strong>Step 2:</strong> Pace yourself and manage your time wisely during the contest. Aim for accuracy rather than speed.</li>
        </ul>
        <p><strong>Tip:</strong> Read each question carefully and eliminate wrong choices before selecting your answer.</p>
    </div>

    <div className="section">
        <h2>8. Wait for the Results</h2>
        <ul>
            <li><strong>Step 1:</strong> After completing the contest, your responses will be evaluated based on accuracy and time taken.</li>
            <li><strong>Step 2:</strong> Results will be announced on the platform within the stipulated time. Keep an eye on your dashboard for updates.</li>
        </ul>
        <p><strong>Tip:</strong> Use this waiting time to revisit the material or explore new chapters.</p>
    </div>

    <div className="section">
        <h2>9. Check Your Score and Prizes</h2>
        <ul>
            <li><strong>Step 1:</strong> Once the results are announced, check your score on the contest leaderboard.</li>
            <li><strong>Step 2:</strong> If you’ve ranked among the top performers, you may be eligible for exciting prizes or rewards.</li>
        </ul>
        <p><strong>Tip:</strong> Even if you didn’t win this time, you can check your performance report to see where you can improve for the next contest!</p>
    </div>

    <div className="section tips">
        <h2>Tips to Increase Your Winning Chances:</h2>
        <ul>
            <li><strong>Study Consistently:</strong> Don't rush through chapters. Give enough time to each subject to ensure a strong understanding.</li>
            <li><strong>Practice Regularly:</strong> Use the practice mode to test your knowledge and become familiar with the question patterns.</li>
            <li><strong>Time Management:</strong> In the contest, allocate time for each question and avoid spending too long on one.</li>
            <li><strong>Stay Updated:</strong> Follow notifications and updates on upcoming contests to avoid missing any.</li>
            <li><strong>Revise Beforehand:</strong> Last-minute revision helps refresh key concepts, which can be vital in scoring high.</li>
        </ul>
    </div>

    <div className="section cta">
        <p>Join the PriceClasses Contest Today!</p>
        <p>Sign up, learn, and compete for exciting prizes while improving your academic knowledge! Each contest is a new opportunity to test your skills and win rewards!</p>
    </div>
      </Box>
    </React.Fragment>
  );
}

export default NotificationsPage;
