import React, { useState, useEffect } from 'react';
import { Avatar, Box, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Popover from '@mui/material/Popover';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

const Sidebar = () => {
  const [anchorEl, setAnchorEl] = useState(null);  // For controlling popover
  const [profileData, setProfileData] = useState(null);  // Store user profile data
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // Check if user is logged in
  const navigate = useNavigate();

  // Open the popover
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);  // Set the button element as anchor
  };

  // Close the popover
  const handlePopoverClose = () => {
    setAnchorEl(null);  // Close the popover
  };

  const isPopoverOpen = Boolean(anchorEl);

  useEffect(() => {
    const userdata = localStorage.getItem('userdata');
    
    if (userdata) {
      const user = JSON.parse(userdata);
      setProfileData(user);  // Set profile data from localStorage
      setIsLoggedIn(true);   // Mark user as logged in
    } else {
      setIsLoggedIn(false);  // No user data in localStorage, not logged in
    }
  }, []);  // Empty dependency array means this runs once on component mount

  return (
    <>
      {/* Popover that opens when Menu button is clicked */}
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            marginTop: '10px',  // Custom vertical offset
            marginLeft: '-25px',  // Custom horizontal offset
            cursor: 'pointer',
          }
        }}
      >
        <Box sx={{ width: 300 }} role="presentation" style={{color:'purple'}} >
          
          {/* Profile Section - Show only if logged in */}
          {isLoggedIn ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#FFA500',
                color: 'white',
                padding: '16px',
              }} 
              onClick={() => navigate('/profile')}
            >
        
             {/* Show profile data after loading */}
          
              <Avatar alt={profileData?.name} src="/path/to/profile-pic.jpg" sx={{ width: 56, height: 56, marginRight: '16px', border: '2px solid #F3E5F5',  backgroundColor: '#813588' }} />
              <Box>
                <Typography variant="h6">{profileData?.name}</Typography>
                <Typography variant="body2">{profileData?.email}</Typography>
                <br/>
                <Typography variant="body2" color='black'>USERID: {profileData?.user}</Typography>
              </Box>
              <ArrowForwardIosIcon style={{ marginLeft: '50px' }} />
            
            </Box>
          ) : (
            // Login option if not logged in
            <List>
              <ListItem disablePadding onClick={() => navigate('/login')}>
                <ListItemButton>
                  <ListItemIcon style={{ color: 'purple' }}>
                    <LoginIcon />
                  </ListItemIcon>
                  <ListItemText primary="Login" />
                </ListItemButton>
              </ListItem>
            </List>
          )}

          {/* Other Sidebar Options */}
          <List>
            <ListItem disablePadding onClick={() => navigate('/subscription')}>
              <ListItemButton>
                <ListItemIcon style={{color:'purple'}}>
                  <SubscriptionsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="My Subscriptions" />
              </ListItemButton>
            </ListItem>
          </List>

          <Divider />

          <List>
            {['Refer & Earn', 'How to win Contest', 'Disclaimer', 'Privacy Policy', 'Terms And Conditions'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => navigate(index === 0 ? '/refer-and-earn' : index === 1 ? '/how' : index === 2 ? '/legality' : index === 3 ? 'privacy' : '/terms')}>
                  <ListItemIcon style={{color:'purple'}}>
                    {index === 0 ? <GroupAddOutlinedIcon /> : index === 1 ? <EmojiEventsOutlinedIcon /> : index === 2 ? <GavelOutlinedIcon /> : index === 4 ? < ShieldOutlinedIcon /> :<DescriptionOutlinedIcon/> }
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Divider />

          <List>
            <ListItem disablePadding onClick={() => navigate('/help')}>
              <ListItemButton>
                <ListItemIcon style={{color:'purple'}}>
                  <SupportAgentOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="24x7 Help & Support" />
              </ListItemButton>
            </ListItem>
          </List>
          {isLoggedIn ? (
              <>
                <Divider />
                <List>
                  <ListItem disablePadding onClick={() => {
                    localStorage.clear();
                    navigate('/login')
                    // window.location.reload(); // This will reload the page
                  }}>
                    <ListItemButton>
                      <ListItemIcon style={{ color: 'purple' }}>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </>
            ) : (
              ''
            )}
            
          <Box sx={{ padding: '16px', textAlign: 'center' }}>
            <Typography variant="caption" color="textSecondary">
              VERSION 2.20.1
            </Typography>
          </Box>
        </Box>
      </Popover>

      {/* Trigger Button for Popover */}
      <MenuOutlinedIcon 
        onClick={handlePopoverOpen}  // Trigger popover on click
        style={{ marginRight: '10px', padding: 2, color: '#813588', backgroundColor: '#f3e5f5', borderRadius: '10px', border: '5px solid #f3e5f5' }}
      />
    </>
  );
};

export default Sidebar;
