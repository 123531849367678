import React, { useRef, useMemo, useCallback,useEffect,useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './ResultPage.module.css'; // CSS Module
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppContext } from '../AppContext';
import useDisableBackButton from '../useDisableBackButton'; // Adjust the path

const ResultPage = () => {
  useDisableBackButton(); // Disable the browser back/forward buttons
  const navigate = useNavigate();
  const location = useLocation();
  const { showModal, hideModal } = useAppContext();
  const isUploading = useRef(false);  // To check if the upload is in progress
  const [update, setUpdate] = useState(true);
  const Entry = localStorage.getItem('Entry');
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault(); // Prevent default back button behavior
      navigate('/', { replace: true });  // Always navigate to the main page
    };

    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);
  // Extracting data passed from previous page
  const { totalQuestions, points, timeTaken, topic } = location.state || {};

  const contestId = localStorage.getItem('CId');
  localStorage.removeItem(contestId);
  const user = localStorage.getItem('userdata');
  const userdata = JSON.parse(user);
  const userId = userdata.user;
  const correctAnswer = localStorage.getItem('CA')
  const wrongAnswer = localStorage.getItem('WA')
  const replyedAnswer = localStorage.getItem('AQ')

  // Memoize the postData object so that it only updates when its dependencies change
  const postData = useMemo(() => ({
    contestId,
    userId,
    points,
    time: timeTaken,
    totalAnswer:totalQuestions,
    correctAnswer,
    wrongAnswer,
    replyedAnswer
  }), [contestId, userId, points, timeTaken,totalQuestions,correctAnswer,wrongAnswer,replyedAnswer]);

  // Upload data function triggered by the upload button
  const uploadData = useCallback(async () => {
    if (isUploading.current) return;  // Prevent multiple uploads
    isUploading.current = true;  // Mark as uploading
    if (update) {
    try {
      showModal({
        circularProgress: true,
        topic: 'Submiting Result...',
        description: 'Please wait...',
      });

      const response = await fetch('https://prizeclasses.in/sql/result.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      // console.log (response);
      console.log (response.status);


      if (response.status === 200) {
        // Show success message and hide spinner
        showModal({
          circularProgress: false,
          topic: 'Answer subimitted',
          description: 'Answer is submited successfully!',
          primaryBtnText: 'OK',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
          },
        });
        setUpdate(false)
      } else {
        showModal({
          circularProgress: false,
          topic: 'Submition Failed',
          description: 'Check your internet and try again',
          primaryBtnText: 'OK',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
          },
        });
      }
    } catch (error) {
      // Handle error if upload fails
      hideModal();

    } finally {
      isUploading.current = false;  // Reset upload state
    }
  }else{
    showModal({
      circularProgress: false,
      topic: 'Already Submited',
      description: 'Your answer is already submited!',
      primaryBtnText: 'OK',
      hidePrimary: false,
      onPrimaryClick: () => {
        hideModal();
      },
    });
  }
  }, [postData, showModal, hideModal,update]);

  return (
    <>
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#A42FC1' }}>
        <Toolbar>
          <IconButton onClick={() => navigate('/')}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            {topic}
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={styles.quizScreen}>
        <div className={styles.pageCard}>
          <div className={styles.quizContainer}>
            <div className={styles.circularProgress}>
              <div className={styles.progressCircle}>
                <span className={styles.progressText}>Your score<br />{points} Pts</span>
              </div>
            </div>
            <span style={{color:'yellow',fontWeight:'bold'}}>Time: {timeTaken / 1000} sec</span>
          </div>

          <div className={styles.questionCard}>
            <div className={styles.header}>
              <div style={{color:'blue',fontWeight:'bold'}}>{localStorage.getItem('AQ')}<br />Attended</div>
              <div style={{color:'violet',fontWeight:'bold'}}>{totalQuestions} <br />Total Questions</div>
            </div>
            <div className={styles.header}>
              <div className={styles.attendedQuestions}>{localStorage.getItem('CA')}<br /> Correct</div>
              <div className={styles.totalQuestions}>{localStorage.getItem('WA')}<br />Wrong</div>
            </div>
          </div>

          <div className={styles.optionsContainer}>
          <button className={styles.optionButton2} onClick={uploadData} style={{display:Entry === 'Contest' ? 'visible' : 'none'}}>  {/* Trigger upload */}
              <span className={styles.optionText}>Submit Answer</span>
            </button>
            <button className={styles.optionButton} onClick={() => navigate('/ranking')} style={{display:Entry === 'Contest' ? 'true' : 'none'}}>
              <span className={styles.optionText}>Ranking</span>
            </button>
            <button className={styles.optionButton} onClick={() => navigate('/review')}>
              <span className={styles.optionText}>Review</span>
            </button>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultPage;
