import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useAppContext } from '../AppContext';
import useDisableBackButton from '../useDisableBackButton'; // Adjust the path

const RankPage = () => {
  useDisableBackButton(); // Disable the browser back/forward buttons
  const [contests, setContests] = useState([]);
  const [wallet, setWallet] = useState('00.00');
  const [rank, setRank] = useState({});
  const [check, setCheck] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { showModal, hideModal } = useAppContext();

  const user = localStorage.getItem('userdata');
  const userdata = JSON.parse(user);
  const userId = userdata.user;

  useEffect(() => {
    const user = localStorage.getItem('userdata');
    if (!user) {
      showModal({
        circularProgress: false,
        topic: 'UserID Not Found',
        description: 'Please log in to continue.',
        primaryBtnText: 'Login',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login');
        },
      });
      return;
    }
  }, [hideModal, navigate, showModal]);

  const fetchContestDataWithGet = useCallback(() => {
    setLoading(true);
    const contestId = localStorage.getItem('CId');
    if (!contestId) {
      showModal({
        circularProgress: false,
        topic: 'Contest Not Found',
        description: 'Please log in to continue.',
        primaryBtnText: 'Login',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login');
        },
      });
      return;
    }

    fetch(`https://prizeclasses.in/sql/contestprize.php?contestId=${contestId}&userId=${userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'error') {
          setContests(data);
          console.log('Response from server:', data);
          const [contestData] = data;
          const balance = contestData?.balance;
          setWallet(balance);
          setLoading(false);
        } else {
          showModal({
            circularProgress: false,
            topic: 'Error',
            description: `Failed to retrieve contest details: ${data.message}`,
            primaryBtnText: 'Retry',
            primaryBtnColor: 'orange',
            hidePrimary: false,
            onPrimaryClick: () => {
              hideModal();
              fetchContestDataWithGet();
            },
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        showModal({
          circularProgress: false,
          topic: 'Network Error',
          description: 'Unable to reach the server. Please check your connection and try again.',
          primaryBtnText: 'Retry',
          primaryBtnColor: 'orange',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
            fetchContestDataWithGet();
          },
        });
        setLoading(false);
      });
  }, [hideModal, showModal, navigate, userId]);

  useEffect(() => {
    fetchContestDataWithGet();
  }, [fetchContestDataWithGet]);

  // Handle contest state and result checking with setInterval
  useEffect(() => {
    const interval = setInterval(() => {
      setContests((prevContests) =>
        prevContests.map((contest) => {
          const currentTime = new Date().getTime();
          const contestResultTime = new Date(contest.resultTime).getTime();
          const newState = { ...contest };

          if (currentTime < contestResultTime) {
            newState.contestState = {
              state: 'ranking',
              timeRemaining: contestResultTime - currentTime,
            };
          } else {
            newState.contestState = {
              state: 'rankout',
            };
            if (check) {
              const contestId = localStorage.getItem('CId');
              setCheck(false);
              fetch(`https://prizeclasses.in/sql/ranking.php?contestId=${contestId}`)
                .then((response) => response.json())
                .then((data) => {
                  const filter = data.data;
                  const status = data.data.status;
                  if (status !== 'Error') {
                    setRank(filter);
                    console.log('message:', filter);

                  } else {
                    console.log('Error message:', filter.message);
                  }
                })
                .catch((error) => {
                  console.error('Error fetching data:', error.message);
                  setCheck(false); // Allow retry on error
                });
            }
          }
          return newState;
        })
      );
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, [contests, check]);

  if (loading)
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <div className="loading">Loading...</div>
      </div>
    );

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {contests.map((contest) => {
        const contestState = contest.contestState || {};

        const { state = 'ranking', timeRemaining = 0 } = contestState;
        const hours = Math.floor((timeRemaining / 1000 / 60 / 60) % 60);
        const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
        const seconds = Math.floor((timeRemaining / 1000) % 60);
        const percent = contest.player > 0 ? Math.floor((contest.totalJoined / contest.player) * 100) : '0';
        const left = contest.player > 0 ? Math.floor(contest.player - contest.totalJoined) : 0;

        return (
          <div key={contest.contestId} style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#A42FC1' }}>
              <Toolbar style={{ justifyContent: 'space-between' }}>
                <IconButton onClick={() => navigate('/')}>
                  <ArrowBackIcon style={{ color: 'white' }} />
                </IconButton>
                <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
                  Ranking
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '120px',
                    height: '35px',
                    color: '#813588',
                    backgroundColor: '#f3e5f5',
                    borderRadius: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <AccountBalanceWalletOutlinedIcon style={{ padding: 2, color: '#813588' }} onClick={() => navigate('/wallet')} />
                  <div>₹{wallet}</div>
                  <AddCircleIcon style={{ padding: 2, color: 'green' }} onClick={() => navigate('/wallet')} />
                </Box>
              </Toolbar>
            </AppBar>

            <div className="pcard-container" style={{ margin: '10px', backgroundColor: 'white', borderRadius: '10px', padding: '10px' }}>
              <div className="ocard-badgebox">
                <div className="pcard-left">#{contest.contestId}</div>
                <div className="ocard-badge">{contest.batchName}</div>
              </div>
              <hr />
              <div className="flex">
                <div className="bar">
                  <p style={{ fontSize: '13px' }}>PrizePool</p>
                  <span style={{ fontWeight: '900', fontSize: '22px' }}>₹{contest.prizePool}</span>
                </div>
                <div className="barR">
                  {state === 'rankout' ? (
                    <p style={{ margin: 0, fontSize: '12px', fontWeight: '600', color: 'red', background: '#ffd1b7', borderRadius: '5px', textAlign: 'center', width: '60px', height: '20px' }}>
                      Rank Out
                    </p>
                  ) : (
                    <p style={{ margin: 0, fontSize: '12px', fontWeight: '600', color: 'red', background: '#ffd1b7', borderRadius: '5px', textAlign: 'center', width: '75px', height: '18px' }}>
                      {hours === 0 ? '' : hours + 'h'} {minutes}m {seconds}s
                    </p>
                  )}
                  <p style={{ margin: 0, fontSize: '13px' }}>{new Date(contest.resultTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                </div>
                <div className="barR">
                  <p className="pcard-left" style={{ margin: 0 }}>
                    Duration<br />
                    {contest.finishTime} sec
                  </p>
                  <p className="pcard-left" style={{ fontSize: '12px', fontWeight: '900', color: 'black', background: '#ffd1b7', borderRadius: '5px', textAlign: 'center' }}>
                    ₹ {contest.entryFee}
                  </p>
                </div>
              </div>
              <hr/>
              <hr/>
              <hr/>
              <div className="ocard-box">
                <div style={{ width: '30%', maxWidth: '75px', textAlign: 'right' }}>{left} Left</div>
                <div className="progress-bar-container">
                  <div className="progress-bar">
                    <div className="progress-fill-bar" style={{ backgroundColor: 'orange', width: `${percent}%` }}></div>
                  </div>
                </div>
                <div style={{ width: '35%', maxWidth: '80px', margin: '3px' }}>{contest.player} Spots</div>
              </div>
            </div>

            <div
              style={{
                background: 'white',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                padding: '10px',
                overflow: 'hidden',
                height: '60%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              <div style={{ flex: '1', marginBottom: '10px', padding: '5px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '13px', fontWeight: 'bold', color: 'dark', borderBottom: '1px solid lightblue', padding: '10px' }}>
                  <div>RANK</div>
                  <div>USERID</div>
                  <div>POINTS</div>
                  <div>Incorrect</div>
                  <div>STATUS</div>
                </div>

                {/* Dynamic Rows based on rank data and prize amount filtering */}
                {Object.keys(rank).map((key, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontSize: '12px',
                      fontWeight: '600',
                      borderBottom: '0.5px solid lightblue',
                      padding: '10px',
                      background: rank[key].userId === userId ? '#fef4dc' : 'transparent', // Highlight if it's the user
                    }}
                  >
                    <div>{`${index + 1}`}</div>
                    <div>{rank[key].userId}</div>
                    <div>{rank[key].points}</div>
                    <div>{rank[key].incorrect} Ans.</div>

                    <div style={{display:`${rank[key].userStatus}` !== 'completed'?'visible':'none'}}>{rank[key].userStatus}</div>
                    <div style={{display:`${rank[key].userStatus}` === 'completed'?'visible':'none'}}>{rank[key].remark}</div>
                  </div>
                ))}
                <div style={{margin:'10px',display:`${contest.contestType}` === "Time-Based" ? "visible":"none", color:'red'}}>Note:- For Time-Based contest winner need to attended all question with correct answer in contest time period.</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RankPage;
