
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import styles from '../logins/SignUpPage.module.css';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';




const MyBalance = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);  // Store user profile data

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate('/');
  };
  const historypage = () => {
    navigate('/history');
  };
  const [upi, setUpi] = useState('');
  const [cashout, setCashout] = useState('');
  const [addcash, setAddcash] = useState('');
  const [balance, setBalance] = useState('');


  const cardStyle = {
    boxShadow: '0 4px 12px rgba(252, 183, 194, 0.816)', // Pink shadow
    backgroundColor: '#F2F2F2',
    borderRadius: '10px',
    padding: '20px',
    marginBottom: '20px',
    };

  const balanceStyle = {
    fontSize: '32px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '10px'
  };

  const buttonStyle = {
    backgroundColor: '#28a745',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '200px',
    fontSize: '16px'
  };

  useEffect(() => {
    const userdata = localStorage.getItem('userdata');
    if (userdata) {
      const user = JSON.parse(userdata);
      setProfileData(user);  // Set profile data from localStorage
    fetch(`https://prizeclasses.in/sql/wallet.php?userId=${profileData?.user}`, {
      method: 'GET', // Adjust if needed (e.g., 'POST')
      headers: {
          'Content-Type': 'application/json',
      },
  })
      .then(response => response.json()) // Parse the JSON response
      .then(data => {
          console.log('Response from server:', data);
  
          // If data is an array, destructure it to get the first object.
          const [contestData] = data;
  
          // Access 'balance' from the contestData object.
          const balance = contestData?.wallet_balance;
  
          // Log the balance value
          // console.log('Balance:', balance);
          setBalance(balance)
  
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });  

    } else {
    }
  }, [profileData?.user]);  // Empty dependency array means this runs once on component mount

 
    const handleAddcashClick = () => {
      window.location.href = 'https://prizeclasses.in/Gateway/Addcash.php'; // Replace with your external link
  };
    const handleCashoutClick = () => {
      window.location.href = 'https://prizeclasses.in/Gateway/Cashout.php'; // Replace with your external link
  };



  return (
    <div>
      
      {/* AppBar */}
      <AppBar position="sticky" style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar style={{justifyContent:'space-between'}}>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" style={{ color: 'white', marginLeft: '8px' }}>
            Wallet
          </Typography>
          <button className='Add' onClick={historypage}>History</button>
        </Toolbar>
      </AppBar>

    <div style={{margin:'20px'}}>
      
      <div style={cardStyle} >
         <div style={{ textAlign: 'center', marginBottom: '10px',margin:'1Opx' }}>CURRENT BALANCE</div>
         <div style={balanceStyle}>₹{balance}</div>

        <div style={{display:'grid',justifyContent:'center'}}>
          <div className={styles.signUpinput} style={{justifyContent:'space-between'}}>
         <button className='Add'>₹25</button>
         <button className='Add'>₹50</button>
         <button className='Add'>₹100</button>
          </div>
          <br/>
          <div className={styles.signUpinput}>
          <PinOutlinedIcon  className={styles.signUpicon} />
            <input
              type="number"
              placeholder="Amount"
              value={addcash}
              onChange={(e) => setAddcash(e.target.value)}
            />
          </div>
        </div> 
        
        <div style={{margin:'20px', display:'flex',justifyContent:'center'}}>
       <button style={buttonStyle} onClick={handleAddcashClick}>ADD CASH</button>
       </div>
      </div>

      <div style={cardStyle} >
         <div style={{ textAlign: 'center', marginBottom: '10px',fontWeight:'bolder' }}>CASH OUT</div>

        <div style={{display:'grid',justifyContent:'center'}}>

          <div className={styles.signUpinput}>
            <AccountBalanceWalletOutlinedIcon className={styles.signUpicon} />
            <input
              type="email"
              placeholder="UPI ID"
              value={upi}
              onChange={(e) => setUpi(e.target.value)}
            />
          </div>
          <br/>
          <div className={styles.signUpinput}>
          <PinOutlinedIcon  className={styles.signUpicon} />
            <input
              type="number"
              placeholder="Amount"
              value={cashout}
              onChange={(e) => setCashout(e.target.value)}
            />
          </div>
        </div> 
        
        <div style={{margin:'20px', display:'flex',justifyContent:'center'}}>
       <button style={buttonStyle} onClick={handleCashoutClick}>CASH OUT</button>
       </div>
      </div>
      </div>
    </div>
  );
};

export default MyBalance;
