import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const PracticePage = () => {
  const [pdfData, setPdfData] = useState([]); // Renamed from 'contests'
  const [loading, setLoading] = useState(true);

  // Memoized fetch function
  const fetchPdfData = useCallback(() => {
    setLoading(true);

    fetch('https://prizeclasses.in/sql/pdf.php') // Update API endpoint
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'error') {
          setLoading(false);
          setPdfData(data); // Store fetched PDF data
        }else{
          setLoading(false);
          console.log(data.message);
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '70vh',
                }}
              >
                <div className="loading">{data.message}</div>
              </div>
            );
        }
      });
  }, []);

  useEffect(() => {
    fetchPdfData(); // Fetch data on component mount
  }, [fetchPdfData]);

  const navigate = useNavigate();

  // Handle PDF click
  const handlePdfClick = (pdfId, status) => {
    if (status === 'locked') {
      alert('This PDF is Locked');
    } else {
      navigate(`/pdf/${pdfId}`); // Navigate to the PDF detail page
    }
  };
  

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <div className="loading">Loading...</div>
      </div>
    );

  return (
    <div style={{ marginTop: '10px' }}>
      <div>
        {pdfData.map((pdf) => (
          <div
            key={pdf.PdfId}
            className="scard-container"
            onClick={() => handlePdfClick(pdf.PdfId, pdf.status)}
          >
            <div className="sflex">
              <div className="bar">
                <p style={{fontWeight: '700', fontSize: '16px',margin:'2px' }}>{pdf.batchName}</p>
                <p
                  style={{ fontWeight: '700', fontSize: '18px', color: 'blue' }}
                >
                  {pdf.PdfName}
                </p>
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '14px',
                    marginBottom: '5px',
                  }}
                >
                {pdf.Year}
                </span>
              </div>

              <div className="barR">
                <p style={{
                    fontWeight: '700',
                    fontSize: '14px',
                    marginBottom: '15px',
                  }}>
                  PDF QUESTION SET
                </p>
                <button
                  style={{
                    background: 'blue',
                    color: 'white',
                    width: '120px',
                    height: '40px',
                    border: '1px solid green',
                    borderRadius: '5px',
                    fontSize: '14px',
                    fontWeight: '700',
                  }}
                >
                  {pdf.status === 'locked' ? '🔒 Unlock' : 'Open'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PracticePage;
