import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import TodayIcon from '@mui/icons-material/Today';


// Utility function to remove expired contests
const removeExpiredContests = (contests) => {
  const currentTime = new Date();
  return contests.filter((contest) => {
    const expiryTime = new Date(contest.startTime).getTime() + 2 * 60 * 1000 + contest.finishTime * 1000;
    return currentTime.getTime() < expiryTime;
  });
};

// Utility function to remove duplicate contests based on contestId
const removeDuplicateContests = (contests) => {
  const seen = new Set();
  return contests.filter((contest) => {
    const duplicate = seen.has(contest.contestId);
    seen.add(contest.contestId);
    return !duplicate;
  });
};

// Function to calculate the state (Join, Revise, Expired)
const getTimeState = (startTime, finishTime) => {
  const currentTime = new Date();
  const contestStartTime = new Date(startTime);
  // const contestFinishTime = contestStartTime.getTime() + finishTime * 1000;
  const revisionStartTime = contestStartTime.getTime();
  const revisionEndTime = revisionStartTime + 2 * 60 * 1000; // 2-minute revision period

  if (currentTime < contestStartTime) {
    return { state: 'join', isExpired: false, timeRemaining: contestStartTime - currentTime };
  } else if (currentTime >= contestStartTime && currentTime <= revisionEndTime) {
    return { state: 'expired', isExpired: true, timeRemaining: revisionEndTime - currentTime };
  } else if (currentTime > revisionEndTime) {
    return { state: 'expired', isExpired: true, timeRemaining: 0 };
  }
};

const PracticePage = () => {
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);

  // Memoized fetch function
  const fetchContestData = useCallback(() => {
    setLoading(true);

    fetch('https://prizeclasses.in/sql/contest.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'error') {
          setLoading(false);

          // Remove expired and duplicate contests
          const freshContests = removeDuplicateContests(data);
          const filteredContests = removeExpiredContests(freshContests)
            .map((contest) => {
              const contestState = getTimeState(contest.startTime, contest.finishTime);
              return { ...contest, contestState }; // Ensure every contest has contestState
            })
            .sort((a, b) => {
              // Sort by 'revise' state first, then by timeRemaining
              if (a.contestState.state === 'revise' && b.contestState.state !== 'revise') return -1;
              if (a.contestState.state !== 'revise' && b.contestState.state === 'revise') return 1;
              return a.contestState.timeRemaining - b.contestState.timeRemaining; // Sort by timeRemaining
            });

          // Update localStorage with the fresh contests
          // localStorage.setItem('contests', JSON.stringify(filteredContests));
          setContests(filteredContests);

        }
      })
      .catch((error) => console.error('Error fetching contest data:', error));

      
  }, []);

  // Load contests from localStorage or fetch from server
  useEffect(() => {
    fetchContestData(); // Fetch new data if localStorage is empty

  }, [fetchContestData]);

  // Countdown updater - runs every second to update remaining time
  useEffect(() => {
    const interval = setInterval(() => {
      setContests((prevContests) =>
        prevContests
          .map((contest) => {
            const contestState = getTimeState(contest.startTime, contest.finishTime);
            return { ...contest, contestState };
          })
          .sort((a, b) => {
            // Sort by 'revise' state first, then by timeRemaining
            if (a.contestState.state === 'revise' && b.contestState.state !== 'revise') return -1;
            if (a.contestState.state !== 'revise' && b.contestState.state === 'revise') return 1;
            return a.contestState.timeRemaining - b.contestState.timeRemaining; // Sort by timeRemaining
          })
      );
    }, 1000);

    return () => clearInterval(interval); // Clear interval when component unmounts
  }, [contests]);

  const navigate = useNavigate();

  // Handle contest click
  const handleContestClick = (contestId, state) => {
    if (state === 'expired') {
      alert('This contest has expired!');
    } else if (state === 'revise') {
      localStorage.setItem('CId', contestId);
      localStorage.setItem('Entry', 'Practice');
      navigate('/entry');
    } else if (state === 'join') {
      localStorage.setItem('CId', contestId);
      localStorage.setItem('Entry', 'Practice');
      navigate('/entry');
    }
  };

  
  if (loading) return <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center',height:'70vh'}}><div className='loading'>Loading...</div></div>  

  return (
    <div style={{marginTop:'10px'}}>
      <div>
        {contests.map((contest) => {
          const contestState = contest.contestState || {}; // Fallback if contestState is undefined
          const { state = 'join', timeRemaining = 0 } = contestState; // Provide default values if state is undefined
          const hours = Math.floor((timeRemaining / 1000 / 60/60) % 60);
          const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
          const seconds = Math.floor((timeRemaining / 1000) % 60);

          return (
            <div
              key={contest.contestId} // Unique key for each contest
              className="pcard-container"
              onClick={() => handleContestClick(contest.contestId, state)
              }
            >
              <div className={state === 'expired' ? "epcard-badgebox" : state === 'revise' ? "rpcard-badgebox" : "pcard-badgebox"} >
                <div className="pcard-left">#{contest.contestId}</div>
                <div className={state === 'expired' ? "epcard-badge" : state === 'revise' ? "rpcard-badge" : "pcard-badge"}>{contest.batchName}</div>
              </div>
              <div className="flex" style={{color: state === 'expired' ? 'gray' :state === 'revise' ?'black':'black' }}>
                <div className="bar" >
                  <p style={{ fontSize: '13px' }}>Questions</p>
                  <span style={{ fontWeight: '900', fontSize: '22px', marginBottom:'5px'}}>{contest.questions}</span>
                  <span style={{ fontWeight: '700', fontSize: '16px' ,color: state === 'expired' ? 'gray' :state === 'revise' ?'green':'blue'}}>MCQ Mock Test</span>
                </div>

                <div className="barR" >
                  {state === 'expired' ? (
                    <p
                      style={{
                        margin: 0,
                        fontSize: '12px',
                        fontWeight: '600',
                        color: 'red',
                        background: 'pink',
                        borderRadius: '5px',
                        textAlign: 'center',
                        width: '60px',
                        height: '20px',
                      }}
                    >
                      Expired
                    </p>
                  ) : (
                    <p
                      style={{
                        margin: 0,
                        fontSize: '12px',
                        fontWeight: '600',
                        color: state === 'revise' ? 'green' : 'red',
                        background: state === 'revise' ? '#C8E6C9' : 'pink',
                        borderRadius: '5px',
                        textAlign: 'center',
                        width: '75px',
                        height: '18px',
                        alignContent:'center',
                      }}
                    >
                      {hours === 0 ? '' : hours + 'h'} {minutes}m {seconds}s
                    </p>
                  )}{state === 'expired' ?'':<p style={{ margin: 0, fontSize: '13px' }}>{new Date(contest.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>}
                </div>

                <div className="barR">
                  <p className="pcard-left">Duration<br></br>{contest.finishTime} sec</p>
                  <button
                    style={{
                      background: state === 'expired' ? 'gray' :  'blue',
                      color: 'white',
                      width: '120px',
                      height: '40px',
                      border: '1px solid green',
                      borderRadius: '5px',
                      fontSize: '14px',
                      fontWeight: '700',
                      cursor: state === 'expired' ? 'not-allowed' : 'pointer',
                    }}
                  >
                    {state === 'expired' ? 'Expired' : ' 🔒 Practice'}
                  </button>
                </div>
              </div>
              <hr />
              <div className={state === 'expired' ? "epcard-box" : state === 'revise' ? "rpcard-box" : "pcard-box"} style={{ color: state === 'expired' ? 'gray' :state === 'revise' ?'green':'blue',textAlign: 'center',width: '100%',height: '35px',  fontWeight:'600'}}>
                <div className="pcard-left" style={{ alignItems:'center', gap:'2px' }}>
                <TodayIcon/> {contest.contestDate}
                </div>
                <div className="pcard-left" >Time-Based
                  {/* {contest.contestType} */}
                  </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PracticePage;
