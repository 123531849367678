import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { useAppContext } from '../AppContext';
import HelpCenterRoundedIcon from '@mui/icons-material/HelpCenterRounded';

// Function to calculate the state (Join, Revise, Joined)
const getTimeState = (startTime, finishTime, joined) => {
  const currentTime = new Date();
  const contestStartTime = new Date(startTime);
  const revisionStartTime = contestStartTime.getTime();
  const revisionEndTime = revisionStartTime + 2 * 60 * 1000; // 2-minute revision period

  if (currentTime >= contestStartTime && currentTime <= revisionEndTime && joined === 'true') {
    return { state: 'revise', isExpired: false, timeRemaining: revisionEndTime - currentTime };
  } else if (currentTime < contestStartTime) {
    return { state: 'join', isExpired: false, timeRemaining: contestStartTime - currentTime };
  } else {
    return { state: 'expired', isExpired: true, timeRemaining: 0 };
  }
};

const MyContestPage = () => {
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showModal, hideModal } = useAppContext();
  const navigate = useNavigate();

  const userdata = JSON.parse(localStorage.getItem('userdata') || '{}');
  const UserId = userdata?.user;

  // Show modal if UserId is not found
  useEffect(() => {
    if (!UserId) {
      showModal({
        circularProgress: false,
        topic: 'Error',
        description: 'User not found. Please log in to continue.',
        primaryBtnText: 'Retry',
        primaryBtnColor: 'orange',
        hidePrimary: false,
        onPrimaryClick: () => {
          hideModal();
          navigate('/login');
        }
      });
    }
  }, [UserId, showModal, hideModal, navigate]);

  const fetchContestData = useCallback(() => {
    if (!UserId) return;

    setLoading(true);
    fetch(`https://prizeclasses.in/sql/mycontest.php?userId=${UserId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'message') {
          const updatedContests = data.map((contest) => {
            const contestState = getTimeState(contest.startTime, contest.finishTime, contest.Joined);
            return { ...contest, contestState };
          });
          // Sort contests: Joined ones appear first, then non-expired, then expired
          updatedContests.sort((a, b) => {
            // Sort by 'revise' state first, then by timeRemaining
            if (a.contestState.state === 'joined' && b.contestState.state !== 'joined') return 1;
            if (a.contestState.state !== 'joined' && b.contestState.state === 'joined') return -1;
            return b.contestState.timeRemaining - a.contestState.timeRemaining; // Sort by timeRemaining
          });

          setContests(updatedContests);
        } else if (data.status === 'message') {
          // Handle 'message' status if needed
        }
      })
      .catch((error) => {
        console.error('Error fetching contest data:', error);
        showModal({
          circularProgress: false,
          topic: 'Error',
          description: `An error occurred while fetching contests: ${error.message}`,
          primaryBtnText: 'Retry',
          primaryBtnColor: 'orange',
          hidePrimary: false,
          onPrimaryClick: () => {
            hideModal();
            fetchContestData(); // Retry fetching contests
          }
        });
      })
      .finally(() => setLoading(false));
  }, [UserId, showModal, hideModal]);

  useEffect(() => {
    if (UserId) {
      fetchContestData();
    }
  }, [fetchContestData, UserId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setContests((prevContests) =>
        prevContests.map((contest) => {
          const contestState = getTimeState(contest.startTime, contest.finishTime, contest.Joined);
          return { ...contest, contestState };
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [contests]);

  const handleContestClick = (contestId, state) => {
    if (state === 'expired') {
      localStorage.setItem('CId', contestId);
      navigate('/ranking');
    } else if (state === 'revise') {
      localStorage.setItem('CId', contestId);
      localStorage.setItem('Entry', 'Contest');
      navigate('/join');
    } else if (state === 'join' || state === 'joined') {
      localStorage.setItem('CId', contestId);
      localStorage.setItem('Entry', 'Contest');
      navigate('/join');
    }
  };

  if (loading) return <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center',height:'70vh'}}><div className='loading'>Loading...</div></div>;

  return (
    <div style={{ marginTop: '10px' }}>
      <div>
        {contests.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '70vh',
            }}
          >
            <div>No contest available</div>
          </div>
        ) : (
          contests.map((contest) => {
            const contestState = contest.contestState || {};
            const { state = 'join', timeRemaining = 0 } = contestState;

            const hours = Math.floor((timeRemaining / 1000 / 60 / 60) % 60);
            const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
            const seconds = Math.floor((timeRemaining / 1000) % 60);

            const percent =
              contest.player > 0
                ? Math.floor((contest.totalJoined / contest.player) * 100)
                : '0';

            return (
              <div
                key={contest.contestId}
                className="pcard-container"
                onClick={() => handleContestClick(contest.contestId, state)}
              >
                <div className={state === 'expired' ? "epcard-badgebox" : state === 'revise' ? "rpcard-badgebox" : "pcard-badgebox"}>
                  <div className="pcard-left">#{contest.contestId}</div>
                  <div className={state === 'expired' ? "epcard-badge" : state === 'revise' ? "rpcard-badge" : "pcard-badge"}>{contest.batchName}</div>
                </div>
                <div className="flex" >
                  <div className="bar">
                    <p style={{ fontSize: '13px' }}>PrizePool</p>
                    <span style={{ fontWeight: '900', fontSize: '22px', margin: '5px' }}>₹{contest.prizePool}</span>
                    <span style={{ textAlign: 'center' }}>{percent}%</span>
                    <div className="progress-bar-container">
                      <div className="progress-bar">
                        <div
                          className="progress-fill-bar"
                          style={{
                            backgroundColor: state === 'expired' ? 'gray' : state === 'revise' ? 'green' : '',
                            width: `${percent}%`
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className="barR">
                    {state === 'expired' ? (
                      <p
                        style={{
                          margin: 0,
                          fontSize: '12px',
                          fontWeight: '600',
                          color: 'red',
                          background: 'pink',
                          borderRadius: '5px',
                          textAlign: 'center',
                          width: '60px',
                          height: '20px',
                        }}
                      >
                        Expired
                      </p>
                    ) : (
                      <p
                        style={{
                          margin: 0,
                          fontSize: '12px',
                          fontWeight: '600',
                          color: state === 'revise' ? 'green' : 'red',
                          background: state === 'revise' ? '#C8E6C9' : 'pink',
                          borderRadius: '5px',
                          textAlign: 'center',
                          alignContent: 'center',
                          width: '75px',
                          height: '18px',
                        }}
                      >
                        {hours === 0 ? '' : hours + 'h'} {minutes}m {seconds}s
                      </p>
                    )}
                    {state !== 'expired' && <p style={{ margin: 0, fontSize: '13px' }}>{new Date(contest.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>}
                  </div>

                  <div className="barR">
                    <p className="pcard-left">Duration<br />{contest.finishTime} sec</p>
                    <button
                      style={{
                        background: state === 'expired' ? 'gray' : state === 'revise' ? 'green' : 'blue',
                        color: 'white',
                        width: '80px',
                        height: '40px',
                        border: '1px solid green',
                        borderRadius: '5px',
                        fontSize: '14px',
                        fontWeight: '700',
                      }}
                    >
                      {state === 'expired' ? 'Ranking' : state === 'revise' ? 'Entry' : 'Joined'}
                    </button>
                  </div>
                </div>
                <hr />
                <div className={state === 'expired' ? "epcard-box" : state === 'revise' ? "rpcard-box" : "pcard-box"} style={{ color: state === 'expired' ? 'gray' : state === 'revise' ? 'green' : 'blue', textAlign: 'center', width: '100%', height: '35px' }}>
                  <div className="pcard-left" style={{ alignItems: 'center', gap: '2px' }}>
                    <SupervisorAccountOutlinedIcon /> {contest.player}
                  </div>
                  <div className="pcard-left" style={{ alignItems: 'center', gap: '2px' }}>
                    <HelpCenterRoundedIcon /> {contest.questions}
                  </div>
                  <div className="pcard-left">{contest.contestType}</div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default MyContestPage;
