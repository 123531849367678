import React, { useState, useEffect } from 'react';
import { Container, AppBar, Toolbar } from '@mui/material';
import Sidebar from './Sidebar';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TodayIcon from '@mui/icons-material/Today';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import ChaptersPage from './ChaptersPage';
import MockPage from './MockPage';
import StudyPage from './StudyPage';
import ContestPage from './ContestPage';
import MyContestPage from './MyContestPage';
import { useNavigate } from 'react-router-dom';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';


const MobileHomePage = () => {
  const navigate = useNavigate();  
  const page = localStorage.getItem('currentpage')?localStorage.getItem('currentpage'):'chapters';
  const [currentPage, setCurrentPage] = useState(page);
  const [batchColor, setBatchColor] = useState('gray');
  const [studyColor, setStudyColor] = useState('gray');
  const [mockColor, setMockColor] = useState('gray');
  const [contestColor, setContestColor] = useState('gray');
  const [historyColor, setHistoryColor] = useState('gray');

  const handleWalletClick = () => {
    navigate('/wallet')
  };

  // Update colors when the page changes
  useEffect(() => {

    if (currentPage === 'chapters') {
      setBatchColor('#813588');
      setMockColor('gray');
      setStudyColor('gray');
      setContestColor('gray');
      setHistoryColor('gray');
    } else if (currentPage === 'study') {
      setBatchColor('gray');
      setMockColor('gray');
      setStudyColor('#813588');
      setContestColor('gray');
      setHistoryColor('gray');
    } else if (currentPage === 'mock') {
      setBatchColor('gray');
      setMockColor('#813588');
      setStudyColor('gray');
      setContestColor('gray');
      setHistoryColor('gray');
    } else if (currentPage === 'contest') {
      setBatchColor('gray');
      setMockColor('gray');
      setStudyColor('gray');
      setContestColor('#813588');
      setHistoryColor('gray');
    } else if (currentPage === 'mycontest') {
      setBatchColor('gray');
      setMockColor('gray');
      setStudyColor('gray');
      setContestColor('gray');
      setHistoryColor('#813588');
    }
  }, [currentPage]);

  // Function to render the current page based on state
  const renderPage = () => {
    localStorage.setItem('currentpage',currentPage)
    if (currentPage === 'chapters') {
      return <ChaptersPage />;
    } else if (currentPage === 'study') {
      return <StudyPage />;
    } else if (currentPage === 'mock') {
      return <MockPage />;
    }else if (currentPage === 'contest') {
      return <ContestPage />;
    }else if (currentPage === 'mycontest') {
      return <MyContestPage />;
    }
  };

  return (
    <div className='black' style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar position='static' style={{ top: 0, width: '100%', backgroundColor: '#813588' }}>
        <Toolbar>
          <Sidebar />
          <span className='Logo' style={{ marginLeft: '10px', flexGrow: 1, fontWeight: 'bolder' }}>
            Prize<span style={{ color: 'orange' }}>Classes</span>
          </span>
          
          <AccountBalanceWalletOutlinedIcon
              style={{
                padding: 2,
                color: '#813588',
                backgroundColor: '#f3e5f5',
                borderRadius: '10px',
                cursor:'pointer',
                border:'5px solid #f3e5f5'
              }} onClick={handleWalletClick}
            />
        </Toolbar>
      </AppBar>

      {/* Main Content Area where pages will be rendered */}
      <Container
        className='main'
        maxWidth='l'
        style={{
          width: '100%',
          marginTop: '0px',
          marginBottom: '60px',
          overflowY: 'auto',
          overflowX: 'hidden',
          flexGrow: 1,
        }}
      >
        {renderPage()}  {/* This will render ChaptersPage or PracticePage based on state */}
      </Container>

      {/* Bottom Navigation */}
      <BottomNavigation
        showLabels
        style={{
          position: 'fixed',
          bottom: 0,
          width: '-webkit-fill-available',
          justifyContent: 'space-between',
          borderTop: '1px solid #ddd',
          zIndex:'2'
        }}
      >
        <BottomNavigationAction
          label='Batches'
          icon={<FolderCopyIcon />}
          onClick={() => setCurrentPage('chapters')}
          style={{ color: batchColor }}
        />
        <BottomNavigationAction
          label='Study Material'
          icon={<LocalLibraryIcon />}
          onClick={() => setCurrentPage('study')}
          style={{ color: studyColor }}
        />
        <BottomNavigationAction
          label='Mock Test'
          icon={<QuizIcon />}
          onClick={() => setCurrentPage('mock')}
          style={{ color: mockColor }}
        />
        
        <BottomNavigationAction
          label='Contest'
          icon={<EmojiEventsIcon />}
          onClick={() => setCurrentPage('contest')}
          style={{ color: contestColor }}
        />
        <BottomNavigationAction
          label='My Contest'
          icon={<TodayIcon />}
          onClick={() => setCurrentPage('mycontest')}
          style={{ color: historyColor }}
        />
      </BottomNavigation>

       </div>
  );
};

export default MobileHomePage;
